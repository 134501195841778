
import { BackendItemDefinition, FrontendItemDefinition, UnionItemDefinition } from "@/types/responses"
import Vue from "vue"
import { Route } from "vue-router"
export default Vue.extend({
	name: "ExtensionItem",
	mounted() {
		this.getExtensionItem()
	},
	watch: {
		$route(to: Route, from: Route) {
			this.compKey = to.params.extenstionItemId
			this.getExtensionItem()
		},
	},
	data(): {
		compKey: string
		extensionItem: (FrontendItemDefinition & BackendItemDefinition) | undefined
	} {
		return {
			compKey: "",
			extensionItem: undefined
		}
	},
	methods: {
		getExtensionItem(): void {
			const modules: UnionItemDefinition[] = this.$vStore.state.nav.modules ? this.$vStore.state.nav.modules["items"] : []
			const extensionsModule = modules.find((module) => {
				if (module.isExtension && module.subItems.length > 0) return module
			})
			const extensionItem = extensionsModule
				? extensionsModule["subItems"].find((extensionItem) => {
						return extensionItem.route === this.$router.currentRoute.path
				})
				: undefined
			this.extensionItem = extensionItem
		},
	},
})
